import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DatosService } from '../../../core/legacy/datos.service';
import {BaseComponent} from '../../layout/base/base.component'
import {Md5} from 'ts-md5';
@Component({
  selector: 'app-associate',
  templateUrl: './associate.component.html',
  styleUrls: ['./associate.component.scss']
})
export class AssociateComponent implements OnInit {

  userSelected:string = "Asociado";
  associated:any = {};
  regions: any;
  civil_states: any;
  cities: any;
  countries: any;
  errorForm: any;
  

  constructor(
    private adminLayout: BaseComponent,   
    private _datos: DatosService,
    private route: Router
  ) {

    /**
     * Vamos a crear dos 
     */
    this.associated = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      rut: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
      city: new UntypedFormControl(null, Validators.required),
      region: new UntypedFormControl(null, Validators.required),
      country: new UntypedFormControl(null, Validators.required),

      legal_name: new UntypedFormControl(null, Validators.required),
      legal_profession: new UntypedFormControl(null, Validators.required),
      legal_rut: new UntypedFormControl(null, Validators.required),
      legal_address: new UntypedFormControl(null, Validators.required),
      legal_civil: new UntypedFormControl(null, Validators.required),
      
      pass1: new UntypedFormControl(null, Validators.required),
      pass2: new UntypedFormControl(null, Validators.required),
      service_condition: new UntypedFormControl(false),
      captcha: new UntypedFormControl(),
    });
   }

  ngOnInit() {
    this.adminLayout.panel = 1;
    this.adminLayout.chrisfooter = 0;
    this._datos.get_region().subscribe(
      res => {
        this.regions = res;
    });
    this._datos.get_country().subscribe(
      res => {
        //console.table(res)
        this.countries = res;
    });
    this._datos.get_civil_status().subscribe(
      res => {
        //console.table(res);
        this.civil_states = res;
    });
  }
  region_selected(){
    var region_id = this.associated.get('region').value;
    if (region_id){
      this._datos.get_cities(region_id).subscribe(
        res => {
          this.cities = res;
      });
    }
  }
  onFormSubmit(){
    var error = false;

    var name = this.associated.get('name').value;
    var rut = this.associated.get('rut').value;
    var email = this.associated.get('email').value;    
    var city_id = this.associated.get('city').value;
    var region_id = this.associated.get('region').value;
    var legal_name = this.associated.get('legal_name').value;
    var legal_profession = this.associated.get('legal_profession').value;
    var legal_rut = this.associated.get('legal_rut').value;    
    var legal_address = this.associated.get('legal_address').value;
    var country_id = this.associated.get('country').value;
    var civil_id = this.associated.get('legal_civil').value;
    var pass1 = this.associated.get('pass1').value;
    var pass2 = this.associated.get('pass2').value;
    var service_condition = this.associated.get('service_condition').value;
    if (name == undefined || name == null || name == ''){
      this.errorForm = 1;
      error = true;
    }else if (email == undefined || email == null || email == ''){
      this.errorForm = 4;
      error = true;
    }else if (!email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)){
      this.errorForm = 5;
      error = true;
    }else if (rut == undefined || rut == null || rut == ''){
      this.errorForm = 2;
      error = true;
    }else if (this.checkRut(rut) != 2){
      this.errorForm = 3;
      error = true;
    }else if (!city_id){
      this.errorForm = 5;
      error = true;
    }else if (!region_id){
      this.errorForm = 6;
      error = true;
    }else if (!country_id){
      this.errorForm = 12;
      error = true;
    }else if (!civil_id){
      this.errorForm = 13;
      error = true;
    }else if (legal_name == undefined || legal_name == null || legal_name == ''){
      this.errorForm = 8;
      error = true;
    }else if (legal_rut == undefined || legal_rut == null || legal_rut == ''){
      this.errorForm = 9;
      error = true;
    }else if (this.checkRut(legal_rut) != 2){
      this.errorForm = 10;
      error = true;
    }else if (legal_address == undefined || legal_address == null || legal_address == ''){
      this.errorForm = 11;
      error = true;
    }else if (pass1 == undefined || pass1 == null || pass1 == ''){
      this.errorForm = 14;
      error = true;
    }else if (pass1.length <= 5){
      this.errorForm = 15;
      error = true;
    } else if (pass2 == undefined || pass2 == null || pass2 == ''){
      this.errorForm = 16;
      error = true;
    }else if (pass2.length <= 5){
      this.errorForm = 18;
      error = true;
    }else if (pass1 != pass2){
      this.errorForm = 17;
      error = true;
    }else if (!service_condition){
      this.errorForm = 19;
      error = true;
    }
    if (!error){
      this._datos.check_email_rut(email,rut,'associated').subscribe(
        res => {
          //console.table(res);
          if (res.status == 1){
            if (res.rows.length > 0){
              this.errorForm = 17;
            }else{
              const md5_pass = Md5.hashStr(pass2);
              var post_data = {
                name : name,
                rut : rut,
                email : email,
                city_id : city_id,
                region_id : region_id,
                legal_name :legal_name,
                legal_profession : legal_profession,
                legal_rut : legal_rut,
                legal_address : legal_address,
                country_id : country_id,
                civil_id : civil_id,
                pass2 :  md5_pass
              }
              //console.table(post_data);
              this._datos.create_web_associated(post_data).subscribe(
                res => {
                  //console.table(res);
                  if (res.status == 1){
                    this.route.navigate(['/auth/confirmation-reg']); 
                  }else{
                    this.route.navigate(['/']); 
                  }
                });
            }
          }else{
            //REPLACE WITH AN ERROR
            this.route.navigate(['/']);
          }
        })
    }

  }
  limpiarform(){
    this.errorForm = 0;
  }
  checkRut(rut:string) {
    rut = rut.toUpperCase();
    // Despejar Puntos
    var valor = rut.replace(/\./g,'');    
    // Despejar Guión
    valor = valor.replace('-','');

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0,-1);
    var dv = valor.slice(-1);

    // Formatear RUN
    rut = cuerpo + '-'+ dv

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if(cuerpo.length < 7) { 
      return 0;
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for(var i=1;i<=cuerpo.length;i++) {

      // Obtener su Producto con el Múltiplo Correspondiente
      var index = multiplo * Number(valor.charAt(cuerpo.length - i));
      
      // Sumar al Contador General
      suma = suma + index;
      
      // Consolidar Múltiplo dentro del rango [2,7]
      if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

}

  // Calcular Dígito Verificador en base al Módulo 11
  var dvEsperado = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  if (dv == 'K'){
    if (dvEsperado == 10)
      return 2;
    else 
      return 1;
  }else if (Number(dv) == 0 ){
    if (dvEsperado == 11)
      return 2;
    else 
      return 1;
  }
  // Validar que el Cuerpo coincide con su Dígito Verificador
  if(dvEsperado != Number(dv)) { 
    return 1;
  }else
    return 2;

  }
}
