<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="login-card card-block auth-body mr-auto ml-auto">
            <form class="md-float-material">
              <div class="auth-box text-center">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-center title-auth-forgot">Felicitaciones</h3>
                  </div>
                </div>              
                <p class="text-inverse b-b-default text-center p-b-5">Hemos enviado un correo de confirmación a tu email.</p>
                <p class="text-inverse b-b-default text-center p-b-5">Tienes 24 horas para confirmar.</p>              
                
                <div class="row">
                  <div class="col-md-12">                  
                    <button type="button" [routerLink]="['/']" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Aceptar</button>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-md-10">
                  </div>
                  <div class="col-md-2">
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="footer-login cotainer-fluid p-t-20 forgot-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <img src="assets/images/logo-cler-small-white-3.png" alt="logo.png" class="logo-forgot">
          </div>
        </div>
      </div>
    </div>
  </div>
  