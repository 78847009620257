import { Component, OnInit } from '@angular/core';
import { DatosService } from "../../../core/legacy/datos.service";
import { BaseComponent } from '../../layout/base/base.component';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import "rxjs/add/operator/map";
//import * as sha512 from 'js-sha512';
import {Md5} from 'ts-md5';
// import { stringify } from '@angular/core/src/util';
import { AuthService } from '../../../core/auth/services/auth.service';
import { PermissionManagerService } from '../../../core/_base/permissions/services/permission-manager.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
    
  ]
})
export class LoginComponent implements OnInit {
  powerCardData: any;
  powerCardOption: any;

  waterCardData: any;
  waterCardOption: any;

  energyCardData: any;
  energyCardOption: any;

  public amountCardData: any;
  public amountCardOption: any;
  public sessionId: string|null = null;
  // public dataUri;
  public findUser: any;
  public groups: any;

  
  public chrisfooter: any = 1;
  
  constructor(private router: Router, 
              private http: HttpClient, 
              private _datos: DatosService, 
              private baseComponent: BaseComponent,
              private authService: AuthService,
              private permissionManagerService: PermissionManagerService
            ) { }

  ngOnInit() {
    this.baseComponent.chrisfooter = 0;   
    this.baseComponent.panel = 1;
    localStorage.removeItem('userId');
    this.baseComponent.logout();

    // // document.addEventListener('DOMContentLoaded', function() {
    //   var iframe: any = document.getElementById('grafanaDashboard');
    //   var dashboardURL = "http://192.168.2.3:3000/d-solo/3J_78b6Z3/clerticket-node3-clerticket-api-ssl?orgId=1&refresh=5s&tab=query&showCategory=Stat+styles&from=1717693530458&to=1717704330458&panelId=64";
    //   // If using API Token
    //   var apiToken = 'Bearer sa-1-clerticket-2749b773-7fb0-49de-8834-f9db14ec963e';
    //   console.log("iframe: ", iframe)
    //   if(iframe){
    //     iframe['src'] = dashboardURL;
    //     iframe.onload = function() {
    //       iframe.contentWindow.postMessage({ message: 'set_token', token: apiToken }, '*');
    //     };
    //   }
    //  });
  }

  change(){
    this.findUser = 0;
  }

  login(form: NgForm){
    this.findUser = 0;
    const md5_pass = Md5.hashStr(form.value.password);
    var login : string = form.value.email;
    if(login.length < 1){
      this.findUser = 2;
    }else{ 
      this.findUser = 1;
      const credentials = {login, password:md5_pass};
        this._datos.login(credentials).subscribe(
        (res: any) => {
          const user = res['user'];
          const token = res['token'];
          this.findUser = [user];
           console.log("login response: ");console.dir(res);
          if(user!=undefined && user!=null && token!=undefined){            
            
            // if (login == res[0].login && md5_pass == res[0].password_crypt && res[0].active == true){
              // this._datos.registerLoginDate(login).subscribe(
              //     res => {
              //         }             
              //   );;
              localStorage.setItem('userId', user.login);
              localStorage.setItem('uid', user.id);
              localStorage.setItem('company_id', user.company_id);
              localStorage.setItem('group_id', user.group_id);
              localStorage.setItem('group_name', user.group_name);
              localStorage.setItem('name_id', user.name_id);
              localStorage.setItem('entity_id', user.entity_id);
              localStorage.setItem('profile_id', user.identificator);
              localStorage.setItem('partner_id', user.partner_id);
              localStorage.setItem('partner_name', user.partner_name);
              localStorage.setItem('is_entity', user.is_entity);
              localStorage.setItem('is_adquisition_unit', user.is_adquisition_unit);
              this.authService.setPartnerName(user.partner_name);
              this.authService.setProfileId(user.identificator);
              this.authService.setToken(token);
              this.authService.setUserIdentificator(`${user.id}`);
              this.authService.setlogin(user.login);

              console.log(`
                userId:${user.login}\n
                uid:${user.id}\n
                company_id:${user.company_id}\n
                name_id:${user.name_id}\n
                entity_id:${user.entity_id}\n
                profile_id:${user.identificator}\n
                partner_name:${user.partner_name}\n
                is_entity:${user.is_entity}\n
                is_adquisition_unit:${user.is_adquisition_unit}\n
              `)
              this.permissionManagerService.authAs(user.identificator)
              // if(user.identificator == 'adm'){
              //   this.router.navigate(['reports-voucher']); 
              // }else{
                this.router.navigate(['/pages/home']); 
              // }
              this.baseComponent.onUserMenu();
            // }else{
            //   this.findUser = 2;
            //   localStorage.clear();
            // }             
          }else{
            this.findUser = 2;
            localStorage.clear();
          }
        }
      )       
    } 
  }

  mostrar(){
    this.sessionId = localStorage.getItem('userId');
    console.log(this.sessionId);
  }  

}