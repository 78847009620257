import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DatosService } from '../../../core/legacy/datos.service';
import {BaseComponent} from '../../layout/base/base.component'
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  // config;
  forgot:any = {};
  errorForm: any;
  forgot_sucess = 0;
  public display='none';
  constructor(
    private adminLayout: BaseComponent,   
    private _datos: DatosService,
    private route: Router

    ) {
    this.forgot = new UntypedFormGroup({
      login: new UntypedFormControl(null, Validators.required),
      //password: new FormControl(null, Validators.required),
      captcha: new UntypedFormControl(),
    });

   }
   async onFormSubmit() {     
    var login_value = this.forgot.get('login').value;
    var is_email = 0;
    var error  = false;
    if (login_value == undefined || login_value == '' || login_value.lenght == 0){
      error = true;
      this.errorForm =1;
    }else if (login_value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)){
      is_email = 1;
    }
    if (!error){
      this._datos.forgot_password(login_value,is_email).subscribe(
        res => {
          var rsp:any; rsp = res;
          //console.log(`return: ${JSON.stringify(rsp)}`);
          if (rsp.length > 0){
            if (rsp[0].email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)){ 
              if(rsp[0].is_forgot){
                //check time difference
                var c_time = new Date();
                var forgot_time = rsp[0].forgot_time;
                var d_time = new Date(forgot_time);
                if (this.time_error(c_time,d_time)){
                  this._datos.request_password_forgot(rsp[0].email).subscribe(
                    res => {
                      var rsp:any; rsp = res;
                      if (rsp.status == 1){
                        this.route.navigate(['/auth/send-confirmation']); 
                      }else{
                        this.route.navigate(['/']); 
                      }             
                    })
                }else{
                  this.errorForm = 3;
                }
              }else{
                this._datos.request_password_forgot(rsp[0].email).subscribe(
                res => {
                  var rsp:any; rsp = res;
                  if (rsp.status == 1){
                    this.route.navigate(['/auth/send-confirmation']);   
                  }                  
                })
              }
            }else{
              this.errorForm =4;
            }
          }else{
            this.errorForm = 4;  
          }                
        });
    }                    
  }/**
   * 
   * @param c_date 
   * @param n_date 
   * @returns 
   */
  time_error(c_date: Date, n_date: Date):boolean{
    var diff =(n_date.getTime() - c_date.getTime()) / 1000;
    diff /= 60;
    var rst = Math.abs(Math.round(diff));
    //console.log(`Checking ${c_date} ${n_date} ${diff} ${rst}`)
    if (rst > 5)
      return true;
    else
      return false;
  }
  ngOnInit() {
    this.adminLayout.panel = 1;
    this.adminLayout.chrisfooter = 0;
  }
  limpiarform(){
    this.errorForm = 0;
  }
}
