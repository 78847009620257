import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import {BaseComponent} from '../../layout/base/base.component'
import {ActivatedRoute,Router} from '@angular/router';
import { DatosService } from '../../../core/legacy/datos.service';
import {Md5} from 'ts-md5';
@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent implements OnInit {
  /**
   * TODO: Pass parameters by reference so it can be checked
   * Receive this parameters and check it on db
   * If there is an issue route to admin page -> it should be route
   * Prepare the form so it can update the user or associated password
   * 
   */
  //pass1;
  //pass2;
  password_update:any = {};
  errorForm: any;
  user_id: any;
  pass_id: any;
  constructor(
    private adminLayout: BaseComponent,    
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private _datos: DatosService,
  ) {
    this.password_update = new UntypedFormGroup({
      pass1: new UntypedFormControl(null, Validators.required),
      //password: new FormControl(null, Validators.required),
      pass2: new UntypedFormControl(null, Validators.required),
    });
   }

  ngOnInit() {
    this.adminLayout.panel = 1;
    this.adminLayout.chrisfooter = 0;
    var token: any = this.activatedRoute.snapshot.params['token'];
    if (token){
      //VALIDATE TOKEN TO DB
      this._datos.validate_token(token,'password').subscribe(
        res => {          
          if (res.length > 0){            
            this.user_id = res[0].user_id;
            this.pass_id = res[0].id;
          }else{
            this.route.navigate(['/']);      
          }
        })
    }else{
      this.route.navigate(['/']);
    }
  }
  limpiarform(){
    this.errorForm = 0;
  }
  onFormSubmit(){
    var error = false;
    var pass1 = this.password_update.get('pass1').value;
    var pass2 = this.password_update.get('pass2').value;
    if (pass1 == undefined || pass1 == ''){
      error = true;
      this.errorForm = 1;
    }else if (pass1.length < 7){
      error = true;
      this.errorForm = 2;
    }else if (pass2 == undefined || pass2 == ''){
      error = true;
      this.errorForm = 3;
    }else if (pass2.length < 7){
      error = true;
      this.errorForm = 4;
    }else if (pass2 != pass1){
      error = true;
      this.errorForm = 5;
    }
    if (!error){
      const md5_pass = Md5.hashStr(pass2);
      this._datos.password(this.user_id,md5_pass,this.pass_id).subscribe(
        res => {
          if (res.status == 1){
            this.route.navigate(['/auth/password-confirmation']);
          }else{
            this.route.navigate(['/']);
          }
        })
    }
  }
}
