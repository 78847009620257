<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="signup-card card-block auth-body mr-auto ml-auto">
            <form [formGroup]="beneficiary" class="md-float-material" (ngSubmit)="onFormSubmit()" autocomplete="off">
              <div class="text-center">
              </div>
              <div class="auth-box">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-center txt-primary title-auth-signup">Inscribirse es rápido y fácil.</h3>
                  </div>
                </div>
                <p class="text-inverse b-b-default text-right"><a [routerLink]="['/auth/login']" class="text-danger" style="text-decoration: none;">Volver al Inicio</a></p>
                <div class="row m-b-20">
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
  
                <div class="titulo-condiciones-servicio">
                  <h3 class="text-left">Registro de beneficiario</h3>
                </div>
  
                <p class="text-inverse b-b-default text-left p-b-5">Ingresa tus datos para iniciar sesión como <strong>beneficiario</strong> en cuenta <strong class="text-danger">Clerticket</strong></p>
  
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" (click)="limpiarform()" placeholder="Ingresa tu nombre" formControlName="name">
                      <div *ngIf="errorForm == 1" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No ingreso nombre"><i class="fa fa-times-circle"></i></div>
                      <span class="md-line"></span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" (click)="limpiarform()" placeholder="Ingresa tu Email" formControlName="email">
                      <div *ngIf="errorForm == 2" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No ingresa correo"><i class="fa fa-times-circle"></i></div>
                      <div *ngIf="errorForm == 3" class="messages text-danger tooltip-error" placement="top" ngbTooltip="Correo en formato incorrecto"><i class="fa fa-times-circle"></i></div>
                      <span class="md-line"></span>
                    </div>
                  </div>                
                  <div class="col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" (click)="limpiarform()" placeholder="RUT" formControlName="rut">
                      <div *ngIf="errorForm == 4" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No ingresa RUT"><i class="fa fa-times-circle"></i></div>
                      <div *ngIf="errorForm == 5" class="messages text-danger tooltip-error" placement="top" ngbTooltip="RUT en formato incorrecto"><i class="fa fa-times-circle"></i></div>
                      <span class="md-line"></span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" (click)="limpiarform()" placeholder="Unidad de compra" formControlName="company_name">
                      <div *ngIf="errorForm == 6" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No ingresa entidad o unidad de compra"><i class="fa fa-times-circle"></i></div>
                      <span class="md-line"></span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <input type="password" class="form-control" (click)="limpiarform()" placeholder="Escoge una Contraseña" formControlName="pass1">
                      <div *ngIf="errorForm == 7" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No ingresa contraseña"><i class="fa fa-times-circle"></i></div>
                      <div *ngIf="errorForm == 10" class="messages text-danger tooltip-error" placement="top" ngbTooltip="Largo debe ser mayor o igual a 6"><i class="fa fa-times-circle"></i></div>
                      <span class="md-line"></span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <input type="password" class="form-control" (click)="limpiarform()" placeholder="Confirma tu Contraseña" formControlName="pass2">
                      <div *ngIf="errorForm == 8" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No ingresa ingresa contraseña"><i class="fa fa-times-circle"></i></div>
                      <div *ngIf="errorForm == 9" class="messages text-danger tooltip-error" placement="top" ngbTooltip="Contraseñas no coinciden"><i class="fa fa-times-circle"></i></div>
                      <div *ngIf="errorForm == 11" class="messages text-danger tooltip-error" placement="top" ngbTooltip="Largo debe ser mayor o igual a 6"><i class="fa fa-times-circle"></i></div>
                      <span class="md-line"></span>
                    </div>
                  </div>
                </div>
  
                <div class="condiciones-servicio">
                  <h6 class="text-left">Condiciones de servicio</h6>
                  <p>La plataforma CLERTICKET se acoge conforme a lo dispuesto en el artículo 19 Nº 4 de la Constitución Política de la República y a las normas pertinentes de la Ley Nº 19.628, sobre protección de la vida privada y sus modificaciones posteriores.</p>
                </div>
  
                <div class="row mt-4 text-left">
                  <div class="col-md-12">
                    <div class="checkbox-fade fade-in-primary">
                      <label>
                        <input type="checkbox"  formControlName="service_condition" (click)="limpiarform()" value="">
                        <div *ngIf="errorForm == 12" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No válida las condiciones de uso"><i class="fa fa-times-circle"></i></div>
                        
                        <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                        <span class="text-inverse">Leo y acepto <a href="javascript:">Terminos &amp; Condiciones.</a></span>
                      </label>
                    </div>
                  </div>
                </div>
                
                <div class="recaptcha-widget">
                  <div appCaptcha key="6LdNf_8bAAAAAAgR6LCRsMQWFVN7Uo52B9yR6P9Z" formControlName="captcha"></div>
                </div>            
                
                <div class="row mt-1">
                  <div class="col-md-8"></div>
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Aceptar</button>
                    <div *ngIf="errorForm == 13" class="messages text-danger tooltip-error" placement="top" ngbTooltip="El correo o rut ya tiene asociada una cuenta"><i class="fa fa-times-circle"></i></div>
                  </div>
                </div>
                <hr/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="footer-login cotainer-fluid p-t-20 forgot-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <img src="assets/images/logo-cler-small-white-3.png" alt="logo.png" class="logo-forgot">
          </div>
        </div>
      </div>
    </div>
  </div>