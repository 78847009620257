import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ForgotComponent } from './forgot/forgot.component';
import { SelectUserComponent } from './select-user/select-user.component';
import { SimpleComponent } from './simple/simple.component';
import { ConfirmationRegComponent } from './confirmation-reg/confirmation-reg.component';
import { AssociateComponent } from './associate/associate.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChangeNoticeComponent } from './change-notice/change-notice.component';
import { PasswordConfirmationComponent } from './password-confirmation/password-confirmation.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { SendConfirmationComponent } from './send-confirmation/send-confirmation.component';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';


@NgModule({
  declarations: [
    LoginComponent,
    ForgotComponent,
    SelectUserComponent,
    SimpleComponent,
    ConfirmationRegComponent,
    AssociateComponent,
    ChangeNoticeComponent,
    PasswordConfirmationComponent,
    PasswordFormComponent,
    SendConfirmationComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    NgScrollbarModule
  ]
})
export class AuthModule { }
