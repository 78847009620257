<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
    <!-- Container-fluid starts -->
    <div class="container-fluid">
      <div class="col-sm-12">
        <h1 class="text-center txt-primary title-auth-signup mb-3">Selecciona tipo de Usuario</h1>      
      </div>
      <div class="row">   
  
  
        <div class="col-sm-6">
          <!-- Authentication card start -->
          <div class="signup-card card-block auth-body mr-auto ml-auto select-user-1">
            <form class="md-float-material">
              <!-- <div class="text-center"> -->
                <!-- <img src="assets/images/logo.png" alt="Gradient Able"> -->
              <!-- </div> -->
              <div class="auth-box">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-center txt-primary title-auth-signup">Beneficiario</h3>
                  </div>
                </div>
                <!-- <p class="text-inverse b-b-default text-right"><a [routerLink]="['/auth/login']" class="text-danger">Volver al Inicio</a></p> -->
              
                <!--<p class="text-inverse b-b-default text-left p-b-5">Inicie sesión fácilmente con su cuenta social:</p>-->
  
                <div class="row m-b-20">
                  <div class="col-md-6">
                    <!--<button type="button" class="btn btn-facebook m-b-20"><i class="fa fa-facebook"></i>Regístrate con facebook</button>-->
                  </div>
                  <div class="col-md-6">
                    <!--<button type="button" class="btn btn-twitter m-b-0"><i class="fa fa-twitter"></i>Regístrate con twitter</button>-->
                  </div>
                </div>
                <p class="text-inverse b-b-default text-left p-b-5">Seleccionar <strong>Beneficiario</strong> para iniciar sesión en cuenta <strong class="text-danger">Clerticket</strong></p>
                <!-- <div class="input-group">
                  <input type="text" class="form-control" placeholder="Escoge nombre de usuario">
                  <span class="md-line"></span>
                </div>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Tú Dirección de Correo Electrónico">
                  <span class="md-line"></span>
                </div>
                <div class="input-group">
                  <input type="password" class="form-control" placeholder="Escoge Contraseña">
                  <span class="md-line"></span>
                </div>
                <div class="input-group">
                  <input type="password" class="form-control" placeholder="Confirma Contraseña">
                  <span class="md-line"></span>
                </div>
                <div class="row m-t-25 text-left">
                  <div class="col-md-12">
                    <div class="checkbox-fade fade-in-primary">
                      <label>
                        <input type="checkbox" value="">
                        <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                        <span class="text-inverse">Leo y acepto <a href="javascript:">Terminos &amp; Condiciones.</a></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="checkbox-fade fade-in-primary">
                      <label>
                        <input type="checkbox" value="">
                        <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                        <span class="text-inverse">Enviame el <a href="javascript:">Newsletter</a> weekly.</span>
                      </label>
                    </div>
                  </div>
                </div>
                
                <div class="recaptcha-widget">
                  <div class="g-recaptcha" data-sitekey="6LdYr00bAAAAAJFU0oWYe5LzsAj5tVU3TT8yAg_w"></div>
                </div> -->           
                
                <div class="row m-t-10 btn-ingresa-aqui-fix">
                  <div class="col-md-12"></div>
                  <div class="col-md-12">
                    <button type="button" [routerLink]="['/auth/simple']" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 btn-ingresa-aqui">Registrarse Aquí</button>
                  </div>
                </div> 
                <hr/>
                <!-- <div class="row">
                  <div class="col-md-10">
                    <p class="text-inverse text-left m-b-0">Thank you and enjoy our website.</p>
                    <p class="text-inverse text-left"><b>Your Authentication Team</b></p>
                  </div>
                  <div class="col-md-2">
                    <img src="assets/images/auth/Logo-small-bottom.png" alt="Gradient Able">
                  </div>
                </div> -->
              </div>
            </form>
            <!-- end of form -->
          </div>
          <!-- Authentication card end -->
        </div>
        <!-- end of col-sm-6 -->
        <div class="col-sm-6">
          <!-- Authentication card start -->
          <div class="signup-card card-block auth-body mr-auto ml-auto select-user-1">
            <form class="md-float-material">
              <!-- <div class="text-center"> -->
                <!-- <img src="assets/images/logo.png" alt="Gradient Able"> -->
              <!-- </div> -->
              <div class="auth-box">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-center txt-primary title-auth-signup">Asociado</h3>
                  </div>
                </div>
                <!-- <p class="text-inverse b-b-default text-right"><a [routerLink]="['/auth/login']" class="text-danger">Volver al Inicio</a></p> -->
              
                <!--<p class="text-inverse b-b-default text-left p-b-5">Inicie sesión fácilmente con su cuenta social:</p>-->
  
                <div class="row m-b-20">
                  <div class="col-md-6">
                    <!--<button type="button" class="btn btn-facebook m-b-20"><i class="fa fa-facebook"></i>Regístrate con facebook</button>-->
                  </div>
                  <div class="col-md-6">
                    <!--<button type="button" class="btn btn-twitter m-b-0"><i class="fa fa-twitter"></i>Regístrate con twitter</button>-->
                  </div>
                </div>
                <p class="text-inverse b-b-default text-left p-b-5">Seleccionar <strong>Asociado</strong> para iniciar sesión en cuenta <strong class="text-danger">Clerticket</strong></p>
                <!-- <div class="input-group">
                  <input type="text" class="form-control" placeholder="Escoge nombre de usuario">
                  <span class="md-line"></span>
                </div>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Tú Dirección de Correo Electrónico">
                  <span class="md-line"></span>
                </div>
                <div class="input-group">
                  <input type="password" class="form-control" placeholder="Escoge Contraseña">
                  <span class="md-line"></span>
                </div>
                <div class="input-group">
                  <input type="password" class="form-control" placeholder="Confirma Contraseña">
                  <span class="md-line"></span>
                </div>
                <div class="row m-t-25 text-left">
                  <div class="col-md-12">
                    <div class="checkbox-fade fade-in-primary">
                      <label>
                        <input type="checkbox" value="">
                        <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                        <span class="text-inverse">Leo y acepto <a href="javascript:">Terminos &amp; Condiciones.</a></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="checkbox-fade fade-in-primary">
                      <label>
                        <input type="checkbox" value="">
                        <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                        <span class="text-inverse">Enviame el <a href="javascript:">Newsletter</a> weekly.</span>
                      </label>
                    </div>
                  </div>
                </div>
                
                <div class="recaptcha-widget">
                  <div class="g-recaptcha" data-sitekey="6LdYr00bAAAAAJFU0oWYe5LzsAj5tVU3TT8yAg_w"></div>
                </div>   -->         
                
                <div class="row m-t-10 btn-ingresa-aqui-fix">
                  <div class="col-md-12"></div>
                  <div class="col-md-12">
                    <button type="button" [routerLink]="['/auth/associate']" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 btn-ingresa-aqui">Registrarse Aquí</button>
                  </div>
                </div> 
                <hr/>
                <!-- <div class="row">
                  <div class="col-md-10">
                    <p class="text-inverse text-left m-b-0">Thank you and enjoy our website.</p>
                    <p class="text-inverse text-left"><b>Your Authentication Team</b></p>
                  </div>
                  <div class="col-md-2">
                    <img src="assets/images/auth/Logo-small-bottom.png" alt="Gradient Able">
                  </div>
                </div> -->
              </div>
            </form>
            <!-- end of form -->
          </div>
          <!-- Authentication card end -->
        </div>
        <!-- end of col-sm-6 -->
        
  
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container-fluid -->
  </section>
  
  <div class="footer-login cotainer-fluid p-t-20 forgot-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <img src="assets/images/logo-cler-small-white-3.png" alt="logo.png" class="logo-forgot">
          </div>
        </div>
        <!-- <div class="col-6">
            <div class="col-sm-12 footer-links login-links">
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Hazte cliente</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Área Comercial</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Emergencias</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Soporte Técnico</a>
            </div>
        </div>
        <div class="col-6 text-right">© 2019 Todos los Derechos Reservados. Cler Entrega respuestas útiles y oportunas en vestuario corporativo, medios de pago e insumos de oficina para que las empresas logren resultados trabajando con agrado, seguridad y confianza.</div> -->
      </div>
    </div>
  </div>