import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DatosService } from '../../../core/legacy/datos.service';
import {BaseComponent} from '../../layout/base/base.component'
import {Md5} from 'ts-md5';
import swal from 'sweetalert2';
@Component({
  selector: 'app-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss']
})
export class SimpleComponent implements OnInit {
  beneficiary:any = {};
  errorForm: any;
  forgot_sucess = 0;

  userSelected:string = "Usuario";

  constructor(
    private adminLayout: BaseComponent,   
    private _datos: DatosService,
    private route: Router
  ) { 
    this.beneficiary = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
      rut: new UntypedFormControl(null, Validators.required),
      company_name: new UntypedFormControl(null, Validators.required),
      pass1: new UntypedFormControl(null, Validators.required),
      pass2: new UntypedFormControl(null, Validators.required),
      service_condition: new UntypedFormControl(false),
      captcha: new UntypedFormControl(),
    });
  }

  ngOnInit() {
    this.adminLayout.panel = 1;
    this.adminLayout.chrisfooter = 0;
  }
  limpiarform(){
    this.errorForm = 0;
  }
  onFormSubmit(){
    var error = false;
    var name = this.beneficiary.get('name').value;
    var email = this.beneficiary.get('email').value;
    var rut = this.beneficiary.get('rut').value;
    var company_name = this.beneficiary.get('company_name').value;
    var pass1 = this.beneficiary.get('pass1').value;
    var pass2 = this.beneficiary.get('pass2').value;
    var service_condition = this.beneficiary.get('service_condition').value;
    /*console.log(`Entering onFormSubmit with values\n
      name: ${name}\n
      email: ${email}\n
      rut: ${rut}\n
      company_name: ${company_name}\n
      pass1: ${pass1}\n
      pass2: ${pass2}\n
      service_condition: ${service_condition}
    `);*/
    if (name == undefined || name == null || name == ''){
      this.errorForm = 1;
      error = true;
    }else if (email == undefined || email == null || email == ''){
      this.errorForm = 2;
      error = true;
    }else if (!email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)){
      this.errorForm = 3;
      error = true;
    }else if (rut == undefined || rut == null || rut == ''){
      this.errorForm = 4;
      error = true;
    }else if (this.checkRut(rut) != 2){
      this.errorForm = 5;
      error = true;
    }else if (company_name == undefined || company_name == null || company_name == ''){
      this.errorForm = 6;
      error = true;
    }else if (pass1 == undefined || pass1 == null || pass1 == ''){
      this.errorForm = 7;
      error = true;
    }else if (pass1.length <= 5){
      this.errorForm = 10;
      error = true;
    } else if (pass2 == undefined || pass2 == null || pass2 == ''){
      this.errorForm = 8;
      error = true;
    }else if (pass2.length <= 5){
      this.errorForm = 11;
      error = true;
    }else if (pass1 != pass2){
      this.errorForm = 9;
      error = true;
    }else if (!service_condition){
      this.errorForm = 12;
      error = true;
    }
    if (!error){
      this._datos.check_email_rut(email,rut,'beneficiary').subscribe(
        res => {
          //console.table(res);
          if (res.status == 1){
            if (res.rows.length > 0){
              this.errorForm = 13;
              swal.fire({
                title: 'Advertencia',
                text: 'El correo o rut ya tiene asociada una cuenta',
                icon: 'error',
              })
            }else{
              const md5_pass = Md5.hashStr(pass2);
              var post_data = {
                name: name,
                email: email,
                rut: rut,
                company_name: company_name,
                pass2: md5_pass
              }
              this._datos.create_user_beneficiary(post_data).subscribe(
                res => {
                  //console.table(res);
                  if (res.status == 1){
                    this.route.navigate(['/auth/confirmation-reg']); 
                  }else{
                    this.route.navigate(['/']); 
                  }
                });
            }
          }else{
            this.route.navigate(['/']); 
          }          
        })
    }
  }
  checkRut(rut:string) {
    rut = rut.toUpperCase();
    // Despejar Puntos
    var valor = rut.replace(/\./g,'');    
    // Despejar Guión
    valor = valor.replace('-','');

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0,-1);
    var dv = valor.slice(-1);

    // Formatear RUN
    rut = cuerpo + '-'+ dv

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if(cuerpo.length < 7) { 
      return 0;
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for(var i=1;i<=cuerpo.length;i++) {

      // Obtener su Producto con el Múltiplo Correspondiente
      var index = multiplo * Number(valor.charAt(cuerpo.length - i));
      
      // Sumar al Contador General
      suma = suma + index;
      
      // Consolidar Múltiplo dentro del rango [2,7]
      if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

}

  // Calcular Dígito Verificador en base al Módulo 11
  var dvEsperado = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  if (dv == 'K'){
    if (dvEsperado == 10)
      return 2;
    else 
      return 1;
  }else if (Number(dv) == 0 ){
    if (dvEsperado == 11)
      return 2;
    else 
      return 1;
  }
  // Validar que el Cuerpo coincide con su Dígito Verificador
  if(dvEsperado != Number(dv)) { 
    return 1;
  }else
    return 2;

  }
}
