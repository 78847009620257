import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { SelectUserComponent } from './select-user/select-user.component';
import { SimpleComponent } from './simple/simple.component';
import { ConfirmationRegComponent } from './confirmation-reg/confirmation-reg.component';
import { ChangeNoticeComponent } from './change-notice/change-notice.component';
import { PasswordConfirmationComponent } from './password-confirmation/password-confirmation.component';
import { AssociateComponent } from './associate/associate.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { SendConfirmationComponent } from './send-confirmation/send-confirmation.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'select-user',
    component: SelectUserComponent
  },
  {
    path: 'simple',
    component: SimpleComponent
  },
  {
    path: 'confirmation-reg',
    component: ConfirmationRegComponent
  },
  {
    path: 'simple',
    component: SimpleComponent
  },
  {
    path: 'change-notice',
    component: ChangeNoticeComponent
  },
  {
    path: 'associate',
    component: AssociateComponent
  },
  {
    path: 'password-form/:token',
    component: PasswordFormComponent
  },
  {
    path: 'send-confirmation',
    component: SendConfirmationComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
