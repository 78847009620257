import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../layout/base/base.component'
import {Router} from '@angular/router';
@Component({
  selector: 'app-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss']
})
export class PasswordConfirmationComponent implements OnInit {

  constructor(
    private adminLayout: BaseComponent,    
    private route: Router,
  ) { }

  ngOnInit() {
    this.adminLayout.panel = 1;
    this.adminLayout.chrisfooter = 0;
    setTimeout (() => {
      //console.log("Hello from setTimeout");
      this.route.navigate(['/']);      
    }, 7000);
  }

}
