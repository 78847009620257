import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../layout/base/base.component'

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit {

  constructor(
    private adminLayout: BaseComponent,   
  ) { }

  ngOnInit() {
    this.adminLayout.panel = 1;
    this.adminLayout.chrisfooter = 0;
  }

}
