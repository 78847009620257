


<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
    <!-- Container-fluid starts -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <!-- Authentication card start -->
          <div class="login-card card-block auth-body mr-auto ml-auto">
            <form class="md-float-material">
              <div class="auth-box">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-left title-auth-forgot">Felicitaciones</h3>
                  </div>
                </div>
                <!-- <p class="text-inverse b-b-default text-right"><a [routerLink]="['/auth/login']" class="text-danger">Volver al Inicio</a></p> -->
                <!-- <div class="input-group">
                  <input class="form-control" placeholder="Su dirección de correo electrónico">
                  <span class="md-line"></span>
                </div> -->
                
                <!-- <div class="recaptcha-widget">
                  <div class="g-recaptcha" data-sitekey="6LdYr00bAAAAAJFU0oWYe5LzsAj5tVU3TT8yAg_w"></div>
                </div>  -->
                <!-- <p class="text-inverse b-b-default text-right"><a [routerLink]="['/auth/login']" class="text-danger">Volver al Inicio</a></p> -->
                
                <p class="text-inverse b-b-default text-left p-b-5">Tu contraseña cambió exitosamente.</p>
                
                <div class="row">
                  <div class="col-md-12">                  
                    <button type="button" [routerLink]="['/']" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Haz click aquí para ingresar a tu cuenta.</button>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-md-10">
                    <!-- <p class="text-inverse text-left m-b-0">Gracias y disfrute de nuestro sitio web.</p>
                    <p class="text-inverse text-left"><b>Su Equipo de Autenticación</b></p> -->
                  </div>
                  <div class="col-md-2">
                    <!-- <img src="assets/images/auth/Logo-small-bottom.png" alt="Gradient Able"> -->
                  </div>
                </div>
              </div>
            </form>
            <!-- end of form -->
          </div>
          <!-- Authentication card end -->
        </div>
        <!-- end of col-sm-12 -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container-fluid -->
  </section>
  <div class="footer-login cotainer-fluid p-t-20 forgot-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <img src="assets/images/logo-cler-small-white-3.png" alt="logo.png" class="logo-forgot">
          </div>
        </div>
        <!-- <div class="col-6">
            <div class="col-sm-12 footer-links login-links">
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Hazte cliente</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Área Comercial</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Emergencias</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Soporte Técnico</a>
            </div>
        </div>
        <div class="col-6 text-right">© 2019 Todos los Derechos Reservados. Cler Entrega respuestas útiles y oportunas en vestuario corporativo, medios de pago e insumos de oficina para que las empresas logren resultados trabajando con agrado, seguridad y confianza.</div> -->
      </div>
    </div>
  </div>
  