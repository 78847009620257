  
  <!-- <div class="row row-centered"> -->
    <!-- <div class="col-md-12 col-centered"> -->
        <div class="login-pagina row">
            <!-- <div class="container-fluid head-login login-logo text-left p-t-20">
              <img class="p-r-50" [src]="'./assets/images/logo-cler.png'" height="30" width="245" alt="Cler ticket logo">
            </div> -->
            <!-- <iframe id="grafanaDashboard" width="450" height="200" frameborder="0"></iframe> -->
            <div class="box-login-fix">
              <div class="xain">
                <div class="logo-login position-relative">
                  <img class="" [src]="'./assets/images/logo-cler.png'" height="30" width="190" alt="Cler ticket logo">
                </div>
                <div *ngIf="findUser == 2" class="alert position-absolute bg-danger" style="top: 10px; right: 10px; left: 10px;">
                  <div class="text-center text-white d-flex justify-content-center align-items-center">
                    <button type="button" class="close ms-auto mt-0 order-2" (click)="findUser = 0">
                      <span class="text-white display-5" aria-hidden="true">&times;</span>
                    </button>
                    <p class="mb-0 ms-auto">Usuario o contraseña incorrecta</p>
                  </div>
                </div>
                      <!-- Authentication card start -->
                        <form #frmLogin="ngForm" class="auth-box-fix">
                            <div class="row m-b-20">
                                <!--<div class="col-md-12 login-logo text-center p-t-30">
                                  <a [routerLink]="['/pages/home/']">
                                    <img src="assets/images/logo-cler.png" height="46" width="300" alt="Atcas">
                                  </a>
                                </div>-->
                              <div class="col-md-12">
    
                                <h5 class="text-center txt-primary m-b-50">Bienvenido/a</h5>
                                <h4 class="text-center txt-primary"><!--<span class="text-primary fa fa-user fa-1x"></span> -->Inicio de Sesión</h4>
                              </div>
                            </div>
                            <hr/>
                            <div class="form-group w-100">
                              <label for="staticEmail">Usuario</label>
    
                                <!-- <span class="input-group-prepend" id="basic-addon1"><label class="input-group-text"><i class="icofont icofont-user-alt-3"></i></label></span> -->
                              <input type="email" class="form-control" (click)="change()" name="email" placeholder="Ingrese email o nombre de usuario." ngModel>
                              <!-- <span class="md-line"></span> -->
                            </div>
                            <div class="form-group w-100">
                              <label for="staticEmail">Contraseña</label>
                                <!-- <span class="input-group-prepend" id="basic-addon1"><label class="input-group-text"><i class="icofont icofont-ui-password"></i></label></span> -->
                              <input type="password" class="form-control" placeholder="Password" name="password" ngModel>
                              <span class="md-line"></span>
                            </div>
                            <div class="row mt-4 text-left w-100">
                              <div class="w-100 px-0">
                                <div class="checkbox-fade fade-in-primary">
                                  <label>
    
                                    <input type="checkbox" value="">
                                    <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                                    <span class="text-inverse">Recordar inicio de sesión</span>
                                  </label>
                                </div>
                                <div class="forgot-phone text-right f-right">
                                  <a [routerLink]="['/auth/forgot']" class="text-right f-w-600 text-inverse text-xs">¿Olvido contraseña?</a>
                                </div>
                              </div>
                            </div>
                                <button type="button" (click)="login(frmLogin)" class="btn btn-primary waves-effect text-center m-b-20 btn-block m-t-30">Entrar</button>
                                <a [routerLink]="['/auth/select-user']" class="text-right f-w-600 text-inverse underline">¿Registrarse?</a>
                                
                            <div class="row mt-4">
                              <div class="col-12">
                                <!--Si olvidó su clave, por favor contactese con el área de soporte.-->
                              </div>
                            </div>
                        </form>
                        <!-- end of form -->
                        <div class="container text-center mb-3">
                          <a class="btn btn-outline-secondary rounded asignacion-btn" [routerLink]="['/pages/late-assignation']">Asignación tardía</a>
                        </div>
    
                      <!-- Authentication card end -->
                    </div>
                    <!-- end of col-sm-12 -->
                  <!-- end of row -->
              </div>
              <!-- <div class="footer-login cotainer-fluid p-t-20">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                        <div class="col-sm-12 footer-links login-links">
                            <a href="#"><i class="fa fa-angle-double-right login-color"></i> Hazte cliente</a>
                            <a href="#"><i class="fa fa-angle-double-right login-color"></i> Área Comercial</a>
                            <a href="#"><i class="fa fa-angle-double-right login-color"></i> Emergencias</a>
                            <a href="#"><i class="fa fa-angle-double-right login-color"></i> Soporte Técnico</a>
                        </div>
                    </div>
                    <div class="col-6 text-right">© 2019 - 2021 Todos los Derechos Reservados. Cler Entrega respuestas útiles y oportunas en vestuario corporativo, medios de pago e insumos de oficina para que las empresas logren resultados trabajando con agrado, seguridad y confianza.</div>
                  </div>
                </div>
              </div> -->
          </div>
        <!-- </div> -->
      <!-- </div> -->
    