<section class="login p-fixed d-flex text-center bg-primary common-img-bg">


    <!-- inicio modals -->
    <div class="backdrop" [ngStyle]="{'display':display}"></div>
      <div class="basic modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered chris-modal col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-7" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <h4 *ngIf="forgot_sucess == 1" class="modal-title"></h4>
            </div>
            <div class="modal-body p-30">
              <ng-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'75vh'">
                <!--<app-send-confirmation></app-send-confirmation>-->
              </ng-scrollbar>
            </div>
            <div class="modal-footer">
              <div class="col-12">
                <div *ngIf="forgot_sucess == 1" class="col-md-12">
                    <div class="f-right">
                      <!--<button type="button" class="btn btn-success"><i class="fa fa-check"></i>Aceptar</button>-->
                    </div>
                    <!--<div class="f-left">
                      <button type="button" align="right" class="btn btn-danger"><i class="fa fa-close"></i>Cancelar</button>
                    </div>
                  -->
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
    <!-- fin modals -->
  
    
    <!-- Container-fluid starts -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <!-- Authentication card start -->
          <div class="login-card card-block auth-body mr-auto ml-auto">
            <form [formGroup]="forgot" class="md-float-material" (ngSubmit)="onFormSubmit()">
              <div class="auth-box">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-left title-auth-forgot">¿Olvidaste tu contraseña?</h3>
                  </div>
                </div>
                <p class="text-inverse b-b-default text-right"><a [routerLink]="['/auth/login']" class="text-danger">Volver al Inicio</a></p>
                <div class="input-group">
                  <input class="form-control" (click)="limpiarform()" placeholder="Ingresar dirección de correo electrónico o login" formControlName="login">
                  <div *ngIf="errorForm == 1" class="messages text-danger tooltip-error" placement="top" ngbTooltip="No ingreso correo"><i class="fa fa-times-circle"></i></div>
                  <div *ngIf="errorForm == 2" class="messages text-danger tooltip-error" placement="top" ngbTooltip="Correo inválido"><i class="fa fa-times-circle"></i></div>
                  <div *ngIf="errorForm == 3" class="messages text-danger tooltip-error" placement="top" ngbTooltip="Solicitud ingresada previamente"><i class="fa fa-times-circle"></i></div>
                  <div *ngIf="errorForm == 4" class="messages text-danger tooltip-error" placement="top" ngbTooltip="Correo electrónico no existe"><i class="fa fa-times-circle"></i></div>
                  <span class="md-line"></span>
                </div>
                
                <div class="recaptcha-widget">                
                  <div appCaptcha key="6LdNf_8bAAAAAAgR6LCRsMQWFVN7Uo52B9yR6P9Z" formControlName="captcha"></div>
                </div> 
                
                <div class="row">
                  <div class="col-md-12">
                    <!--<button type="button" [routerLink]="['/send-confirmation']" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Olvidé mi contraseña</button>-->
                    <button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Recuperar mi contraseña</button>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-md-10">
                    <!-- <p class="text-inverse text-left m-b-0">Gracias y disfrute de nuestro sitio web.</p>
                    <p class="text-inverse text-left"><b>Su Equipo de Autenticación</b></p> -->
                  </div>
                  <div class="col-md-2">
                    <!-- <img src="assets/images/auth/Logo-small-bottom.png" alt="Gradient Able"> -->
                  </div>
                </div>
              </div>
            </form>
            <!-- end of form -->
          </div>
          <!-- Authentication card end -->
        </div>
        <!-- end of col-sm-12 -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container-fluid -->
  </section>
  <div class="footer-login cotainer-fluid p-t-20 forgot-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <img src="assets/images/logo-cler-small-white-3.png" alt="logo.png" class="logo-forgot">
          </div>
        </div>
        <!-- <div class="col-6">
            <div class="col-sm-12 footer-links login-links">
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Hazte cliente</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Área Comercial</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Emergencias</a>
                <a href="#"><i class="fa fa-angle-double-right login-color"></i> Soporte Técnico</a>
            </div>
        </div>
        <div class="col-6 text-right">© 2019 Todos los Derechos Reservados. Cler Entrega respuestas útiles y oportunas en vestuario corporativo, medios de pago e insumos de oficina para que las empresas logren resultados trabajando con agrado, seguridad y confianza.</div> -->
      </div>
    </div>
  </div>